<template>
  <div>

    <!-- <v-navigation-drawer
      app
      fixed
      v-model="showMenu"

    >
      <v-list dense>
        <v-list-item @click="doNothing">
          <v-list-item-action>
            <v-icon>account_box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Log-in</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="doNothing">
          <v-list-item-action>
            <v-icon>account_box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Log-out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-app-bar
      app
      fixed
      dark
      color="primary"

    >
      <!-- <v-app-bar-nav-icon @click.stop="toggleMenu"></v-app-bar-nav-icon> -->
      <v-toolbar-title>Riviera Rentals</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-body-2">v1.12.1</div>
      <v-btn @click="unlog" icon>
        <v-icon>mdi-export</v-icon>
      </v-btn>

    </v-app-bar>

  </div>
</template>



<script>

export default  {

    name: 'TopToolbar',
    components: {
      
    },
    data () {
      return {
      user:{email:'', password:''}
      }
    },
    computed: {

    },
    methods: {
      
      unlog(){
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        this.$emit('toggle', 'unlogin');
        this.$router.push({ name: 'login' })
      }
    },

    mounted()
    {
    },
}
</script>
